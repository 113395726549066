import http from '../../../http-common';

export function requestLoadMtto(id) {
    return http.get(`/mtto/${id}`);
}

export function requestSaveMtto(mtto) {
    return http.post('/mtto', mtto, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
