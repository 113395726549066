/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import getTipoDePagos from '../../services/tipoDePagosService';

export default function MaterialesForm(props) {
    const initialFieldValues = {
        id: 0,
        numeroParte: '',
        numeroMaterial: '',
        proveedor: '',
        precioUnitario: 0,
        facturaProveedor: '',
        fechaCompra: null,
        cantidad: 0,
        concepto: '',
    };
    const [disableButton, setDisableButton] = useState(false);
    const {
        values, setValues, errors, setErrors, handleInputChange, resetForm,
    } = useForm(
        initialFieldValues,
        true,
        // eslint-disable-next-line no-use-before-define
        validate,
    );

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('fechaCompra' in fieldValues) {
            temp.fechaCompra = fieldValues.fechaCompra ? '' : 'Campo Requerido';
        }

        if ('numeroParte' in fieldValues) {
            temp.numeroParte = fieldValues.numeroParte ? '' : 'Campo Requerido';
        }
        if ('numeroMaterial' in fieldValues) {
            temp.numeroMaterial = fieldValues.numeroMaterial ? '' : 'Campo Requerido';
        }
        if ('cantidad' in fieldValues) {
            temp.cantidad = fieldValues.cantidad ? '' : 'Campo Requerido';
        }
        if ('proveedor' in fieldValues) {
            temp.proveedor = fieldValues.proveedor ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { addOrEdit, recordForEdit } = props;

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
    }, [recordForEdit, setValues]);

    const handleSubmit = (e) => {
        setDisableButton(true);
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.numeroParte}
                        label='Numero de Parte *'
                        name='numeroParte'
                        onChange={handleInputChange}
                        value={values.numeroParte}
                    />
                    <Controls.Input
                        error={errors.numeroMaterial}
                        label='Numero de Material *'
                        name='numeroMaterial'
                        onChange={handleInputChange}
                        value={values.numeroMaterial}
                    />
                    <Controls.Input
                        error={errors.proveedor}
                        label='Proveedor *'
                        name='proveedor'
                        onChange={handleInputChange}
                        value={values.proveedor}
                    />
                    <Controls.NumericInput
                        error={errors.precioUnitario}
                        label='Precio Unitario *'
                        name='precioUnitario'
                        onChange={handleInputChange}
                        value={values.precioUnitario}
                    />
                    <Controls.Input
                        error={errors.facturaProveedor}
                        label='Factura Proveedor *'
                        name='facturaProveedor'
                        onChange={handleInputChange}
                        value={values.facturaProveedor}
                    />
                </Grid>
                <Controls.DatePicker
                    error={errors.fechaCompra}
                    label='Fecha de Compra *'
                    name='fechaCompra'
                    onChange={handleInputChange}
                    value={values.fechaCompra}
                />
                <Grid item xs={6}>
                    <Controls.NumericInput
                        error={errors.cantidad}
                        label='Cantidad *'
                        name='cantidad'
                        onChange={handleInputChange}
                        value={values.cantidad}
                    />
                    <Controls.Input
                        error={errors.concepto}
                        label='Concepto *'
                        name='concepto'
                        onChange={handleInputChange}
                        value={values.concepto}
                    />
                    <div>
                        <Controls.Button disabled={disableButton} text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
