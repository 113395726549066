import moment from 'moment';

export const LOAD_MTTO = 'LOAD_MTTO';
export const SET_MTTO = 'SET_MTTO';
export const UPDATE_MTTO = 'UPDATE_MTTO';
export const SAVE_MTTO = 'SAVE_MTTO';
export const SAVED_MTTO = 'SAVED_MTTO';
export const NEW_MTTO = 'NEW_MTTO';

const initialState = {
    mttos: [],
    selectedMtto: {
        id: 0,
        unidad: null,
        mecanico: '',
        kilometraje: null,
        fecha: null,
        operador: null,
        tipo: '',
        sintomas: '',
        descripcionRep: '',
        materiales: [],
    },
};

export const loadMtto = (id) => ({
    type: LOAD_MTTO,
    id,
});

export const setMtto = (mtto) => ({
    type: SET_MTTO,
    mtto,
});

export const updateMtto = (mtto) => ({
    type: UPDATE_MTTO,
    mtto,
});

export function saveMtto(mtto) {
    const mttoCopy = {
        ...mtto,
    };

    if (mtto.fecha !== null) {
        const fecha = moment(mtto.fecha).format('YYYY-MM-DD');
        mttoCopy.fecha = moment.utc(`${fecha} 00:00:00`);
    }
    const { isDirty, ...mttoCopyRest } = mttoCopy;

    if (mttoCopyRest.materiales?.length > 0) {
        mttoCopyRest.materiales = mttoCopyRest.materiales.map((material) => {
            const { isDirty: dirty, ...cleanMaterial } = material;
            return cleanMaterial; // Replace the current material with cleanMaterial
        });
    }

    return {
        type: SAVE_MTTO,
        mtto: mttoCopyRest,
    };
}

export const savedMtto = (mtto) => ({
    type: SAVED_MTTO,
    mtto,
});

export const newMtto = () => ({
    type: NEW_MTTO,
    selectedMtto: initialState.selectedMtto,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case NEW_MTTO:
        console.log('newMtto', action.selectedMtto);
        return {
            ...state,
            selectedMtto: action.selectedMtto,
        };
    case SAVED_MTTO:
    case SET_MTTO:
        const { mtto } = action;
        if (!mtto) {
            return {
                ...state,
                selectedMtto: initialState.selectedMtto,
            };
        }
        return {
            ...state,
            selectedMtto: mtto,
        };

    case UPDATE_MTTO:
        return {
            ...state,
            selectedMtto: action.mtto,
        };

    default:
        return state;
    }
};
