import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeleteForever, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import Moment from '../../components/Moment';
import { Controls } from '../../components/controls/Controls';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import MaterialesForm from './MaterialesForm';
import { filterOnAnyField } from '../../utils';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

export default function Materiales({ values, setValues, setIsDirty }) {
    const classes = useStyles();

    const [openPopupMateriales, setOpenPopupMateriales] = useState(false);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();

    const headCells = [
        { id: 'numeroParte', label: 'Numero de Parte' },
        { id: 'numeroMaterial', label: 'Numero de Material' },
        { id: 'proveedor', label: 'Proveedor' },
        { id: 'precioUnitario', label: 'Precio Unitario' },
        { id: 'facturaProveedor', label: 'Factura Proveedor' },
        { id: 'fechaCompra', label: 'Fecha de Compra' },
        { id: 'cantidad', label: 'Cantidad' },
        { id: 'concepto', label: 'Concepto' },
        { id: 'actions', label: 'Acciones', disableSorting: true },
    ];
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(values.materiales, headCells, filterFn);
    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };
    const addOrEdit = (material) => {
        let materialesList;
        console.log(material);
        if (material.id === 0) {
            const addedMaterial = {
                ...material,
                id: null,
            };
            materialesList = values.materiales.concat(addedMaterial);
        } else {
            const id = values.materiales.findIndex((o) => o.id === material.id);
            materialesList = values.materiales
                .slice(0, id)
                .concat([material])
                .concat(values.materiales.slice(id + 1));
        }
        setValues({
            ...values,
            materiales: materialesList,
        });
        setOpenPopupMateriales(false);
        setIsDirty(true);
    };

    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopupMateriales(true);
    };

    const deleteItem = (item) => {
        const newMateriales = values.materiales.filter((f) => f.id !== item.id);
        setValues({
            ...values,
            materiales: newMateriales,
        });
        setIsDirty(true);
    };
    return (
        <>
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    {' '}
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            setRecordForEdit(null);
                            setOpenPopupMateriales(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.numeroParte}</TableCell>
                                <TableCell>{item.numeroMaterial}</TableCell>
                                <TableCell>{item.proveedor}</TableCell>

                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.precioUnitario}
                                    />
                                </TableCell>
                                <TableCell>{item.facturaProveedor}</TableCell>
                                <TableCell>
                                    <Moment date={item.fechaCompra} />
                                </TableCell>
                                <TableCell>{item.cantidad}</TableCell>
                                <TableCell>{item.concepto}</TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            openInPopup(item);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            deleteItem(item);
                                        }}
                                    >
                                        <DeleteForever fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopupMateriales}
                setOpenPopup={setOpenPopupMateriales}
                title='Materiales'
            >
                <MaterialesForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                    valuesViaje={values.tramos}
                />
            </Popup>
        </>
    );
}
