import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import { loadOperadores } from '../../redux/ducks/operadores';
import { loadUnidades } from '../../redux/ducks/unidades';
import 'date-fns';

export default function TopMantenimiento({
    setIsDirty, values, handleInputChange, errors,
}) {
    const operadores = useSelector((state) => state.operadores.operadores);
    const unidades = useSelector((state) => state.unidades.unidades);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadOperadores(false, false, false));
    }, [dispatch]);
    useEffect(() => {
        dispatch(loadUnidades());
    }, [dispatch]);

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <Grid
                        container
                        direction='column'
                        spacing={0}
                        style={{ paddingLeft: '1rem' }}
                    >
                        <Grid item xs={6}>
                            <Controls.Autocomplete
                                displayField='numeroEconomico'
                                error={errors?.unidad}
                                label='Unidad *'
                                name='unidad'
                                onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                                options={unidades}
                                value={values?.unidad}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                error={errors?.mecanico}
                                label='Mecanico'
                                name='mecanico'
                                onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                                value={values?.mecanico}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                error={errors?.kilometraje}
                                label='Kilometraje'
                                name='kilometraje'
                                onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                                value={values?.kilometraje}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Controls.Autocomplete
                                displayField='nombre'
                                displayField1='apellidos'
                                error={errors?.operador}
                                label='Operador que reporta falla'
                                name='operador'
                                onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                                options={operadores}
                                value={values?.operador}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid
                        container
                        direction='column'
                        spacing={0}
                    >
                        <Grid item xs={6}>
                            <Controls.DatePicker
                                error={errors?.fecha}
                                label='Fecha de reporte de Falla *'
                                name='fecha'
                                onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                                value={values?.fecha}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                error={errors?.tipo}
                                label='Tipo de Mantenimiento'
                                name='tipo'
                                onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                                value={values?.tipo}
                            />
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingRight: '30px', marginTop: '5px', paddingLeft: '10px' }}>
                <Grid item xs={6}>
                    <Typography style={{ paddingLeft: '10px' }} variant='h6'>
                        Síntomas de la falla
                    </Typography>
                    <Controls.Textarea
                        error={errors.sintomas}
                        minRows='5'
                        name='sintomas'
                        onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                        value={values.sintomas}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ paddingLeft: '10px' }} variant='h6'>
                        Descripción de la reparacion
                    </Typography>
                    <Controls.Textarea
                        error={errors.descripcionRep}
                        minRows='5'
                        name='descripcionRep'
                        onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                        value={values.descripcionRep}
                    />
                </Grid>
            </Grid>
        </>
    );
}
