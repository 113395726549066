/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import {
    Box, Grid,
    IconButton, Input, Paper,
    TableBody, TableCell, TableRow, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import Add from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';

import { CancelOutlined, DeleteForever, SaveOutlined } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { loadOperadores } from '../../redux/ducks/operadores';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import PagosExtraForm from './PagosExtraForm';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    newButton1: {
        fontSize: 14,
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            cursor: 'pointer',
        },
    },
}));
const headCells = [
    { id: 'collapse', label: '' },
    { id: 'operador', label: 'Empleado' },
    { id: 'pago', label: 'Pago' },
    { id: 'deducciones', label: 'Deducciones' },
    { id: 'Total', label: 'Total' },
    { id: 'acciones', label: '' },

];

const headTramos = [
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'lugarInicio', label: 'Origen' },
    { id: 'lugarFin', label: 'Destino' },
    { id: 'pago', label: 'Pago Operador' },
    { id: 'anticipos', label: 'Anticipos' },
    { id: 'otrosGastos', label: 'Otros Gastos' },
    { id: 'total', label: 'Total' },
    { id: 'acciones', label: '' },
];

const headDescuentos = [
    { id: 'tipoDescuento', label: 'Tipo Descuento' },
    { id: 'saldoActual', label: 'Saldo' },
    { id: 'descuento', label: 'Descuento' },
    { id: 'nuevoSaldo', label: 'Nuevo Saldo' },
    { id: 'acciones', label: '' },
];

const headPagos = [
    { id: 'tipoPago', label: 'Tipo Pago' },
    { id: 'cantidad', label: 'Cantidad' },
    { id: 'acciones', label: '' },
];
function Row({
    item, updateRecords, removeEmpleado, openForm,
}) {
    const classes = useStyles();
    const [newDescuento, setNewDescuento] = useState(0);
    const [currentEditing, setCurrentEditing] = useState('');

    const onChangeDescuento = (itm, descuento) => {
        const idx = item.descuentos.findIndex((f) => f.tipo === descuento.tipo);
        const descuentos = [
            ...item.descuentos.slice(0, idx),
            {
                ...descuento,
                descuento: Number(newDescuento),
            },
            ...item.descuentos.slice(idx + 1),
        ];
        updateRecords({
            ...item,
            totalDescuentos: descuentos.map((m) => m.descuento).reduce((prev, next) => Number(prev) + Number(next)),
            descuentos: [
                ...descuentos,
            ],
        });
    };

    const deleteEmpleado = (id) => {
        removeEmpleado(id);
    };
    const setOpen = (open) => {
        updateRecords({
            ...item,
            open,
        });
    };
    const addPago = () => {
        openForm(item);
    };

    const deletePago = (pago) => {
        const idx = item.pagos.findIndex((f) => f.id === pago.id);
        const itms = [
            ...item.pagos.slice(0, idx),
            ...item.pagos.slice(idx + 1),
        ];

        updateRecords({
            ...item,
            pago: item.pago - Number(pago.cantidad),
            pagos: [
                ...itms,
            ],
        });
    };
    return (
        <>
            <TableRow key={item.id}>
                <TableCell>
                    <IconButton aria-label='expand item' onClick={() => setOpen(!item.open)} size='small'>
                        {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell>{item.nombre}</TableCell>
                <TableCell>
                    <NumberFormat
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.pago}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.totalDescuentos}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.pago - item.totalDescuentos}
                    />
                </TableCell>
                <TableCell>
                    <Controls.ActionButton
                        color='primary'
                        onClick={() => {
                            deleteEmpleado(item.id);
                        }}
                    >
                        <DeleteForever fontSize='small' />
                    </Controls.ActionButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Grid container>
                                <Typography component='div' gutterBottom variant='subtitle1'>
                                    Pagos Extra:
                                </Typography>
                                <Controls.ActionButton
                                    color='primary'
                                    onClick={() => {
                                        addPago();
                                    }}
                                >
                                    <Add fontSize='small' />
                                </Controls.ActionButton>
                            </Grid>

                            <Table aria-label='pagos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headPagos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.pagos.map((pago) => (
                                            <TableRow key={pago.id}>
                                                <TableCell>{pago.tipoPago}</TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={pago.cantidad}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Controls.ActionButton
                                                        color='primary'
                                                        onClick={() => {
                                                            deletePago(pago);
                                                        }}
                                                    >
                                                        <DeleteForever fontSize='small' />
                                                    </Controls.ActionButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Descuentos:
                            </Typography>
                            <Table aria-label='tramos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headDescuentos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.descuentos.map((descuento) => (
                                            <TableRow key={`descuentos-${descuento.id}`}>
                                                <TableCell>
                                                    {descuento.tipo}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        descuento.recurrente === true ? '' : descuento.saldo
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        currentEditing === descuento.tipo
                                                            ? (
                                                                <Input
                                                                    onChange={(e) => setNewDescuento(e.target.value)}
                                                                    type='number'
                                                                    value={newDescuento}
                                                                />
                                                            )
                                                            : descuento.descuento
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        descuento.recurrente === true ? ''
                                                            : descuento.saldo - descuento.descuento
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        currentEditing !== descuento.tipo
                                                            ? (
                                                                <Controls.ActionButton
                                                                    color='primary'
                                                                    onClick={() => {
                                                                        setNewDescuento(descuento.descuento);
                                                                        setCurrentEditing(descuento.tipo);
                                                                    }}
                                                                >
                                                                    <EditOutlinedIcon fontSize='small' />
                                                                </Controls.ActionButton>
                                                            )

                                                            : (
                                                                <>
                                                                    <Controls.ActionButton
                                                                        color='primary'
                                                                        onClick={() => {
                                                                            onChangeDescuento(item, descuento);
                                                                            setCurrentEditing('');
                                                                        }}
                                                                    >
                                                                        <SaveOutlined fontSize='small' />
                                                                    </Controls.ActionButton>

                                                                    <Controls.ActionButton
                                                                        color='primary'
                                                                        onClick={() => {
                                                                            setCurrentEditing('');
                                                                        }}
                                                                    >

                                                                        <CancelOutlined fontSize='small' />
                                                                    </Controls.ActionButton>

                                                                </>
                                                            )

                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </>
    );
}
function CollapsibleRow({
    item, updateRecords, removeEmpleado, openForm,
}) {
    return (
        <Row
            key={item.id}
            item={item}
            openForm={openForm}
            removeEmpleado={removeEmpleado}
            updateRecords={updateRecords}
        />
    );
}
export default function LiquidacionAdmin() {
    const classes = useStyles();
    const [filterFn] = useState({
        fn: (items) => items,
    });

    const [records, setRecords] = useState([]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(records, headCells, filterFn);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIndex(2, 'Liq'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadOperadores(true, true));
    }, [dispatch]);

    const updateRecords = (op) => {
        const idx = records.findIndex((r) => r.key === op.key);
        op.pago += op.pagos.map((m) => m.cantidad).reduce((prev, next) => Number(prev) + Number(next), 0);
        setRecords([
            ...records.slice(0, idx),
            op,
            ...records.slice(idx + 1),
        ]);
    };

    const actualizar = async () => {
        const data = await http.get('/operadores/pagoEmpleados');
        if (data.data) {
            data.data.forEach((a) => a.pagos = []);
            setRecords(data.data);
        }
    };

    useEffect(() => {
        actualizar();
    }, []);
    const pagar = async () => {
        try {
            const response = await http.post('/operadores/pagoEmpleados', records);
            if (response.status === 200) {
                setRecords([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const removeEmpleado = (id) => {
        const idx = records.findIndex((r) => r.id === id);
        setRecords([
            ...records.slice(0, idx),
            ...records.slice(idx + 1),
        ]);
    };

    const [openPopup, setOpenPopup] = useState(false);
    const [operadorActual, setOperadorActual] = useState();
    const openForm = (item) => {
        setOperadorActual(item);
        setOpenPopup(true);
    };

    const addOrEdit = (op) => {
        updateRecords({
            ...operadorActual,
            pagos: [
                ...operadorActual.pagos,
                {
                    ...op,
                    cantidad: Number(op.cantidad),
                    id: uuidv4(),
                },
            ],
        });
        setOpenPopup(false);
    };

    return (
        <>
            <Box displayPrint='none'>
                <PageHeader
                    icon={<PeopleOutlineTwoToneIcon fontSize='large' />}
                    subtitle=''
                    title='Pago Empleados'
                />
            </Box>
            <Paper className={classes.pageContent}>
                <Box displayPrint='none'>
                    <Grid
                        container
                        justifyContent='flex-start'
                        spacing={1}
                    >

                        <Grid item xs={6}>
                            <Controls.Button
                                className={classes.newButton1}
                                onClick={() => {
                                    actualizar();
                                }}
                                text='Actualizar'
                                variant='outlined'

                            />
                            <Controls.Button
                                className={classes.newButton1}
                                onClick={() => {
                                    pagar();
                                }}
                                text='Pagar'
                                variant='outlined'
                            />

                        </Grid>
                    </Grid>
                </Box>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => <CollapsibleRow key={item.id} item={item} openForm={openForm} removeEmpleado={removeEmpleado} updateRecords={updateRecords} />)}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Cliente'
            >
                <PagosExtraForm
                    addOrEdit={addOrEdit}
                />
            </Popup>
        </>
    );
}
